// Header, Footer, Commons
import trEN from "./en/translations"
import trRO from "./ro/translations"
import trHU from "./hu/translations"

// Pages ===========================
// FARMER
import farmerEN from "./en/farmer"
import farmerRO from "./ro/farmer"
import farmerHU from "./hu/farmer"

// CUSTOMER
import customerEN from "./en/customer"
import customerRO from "./ro/customer"
import customerHU from "./hu/customer"



// Build JSON Object
export const translationEN = {
	...trEN,
	...farmerEN,
	...customerEN
}
export const translationRO = {
	...trRO,
	...farmerRO,
	...customerRO
}
export const translationHU = {
	...trHU,
	...farmerHU,
	...customerHU
}