import { combineReducers } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import userReducer from "./modules/user";
import { pageReducer } from  "@magic/magic-redux"
import { reduxStorageKey } from "./index";
// export const LOGOUT_USER = "LOGOUT_USER";

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem(`persist:${reduxStorageKey}`);
    // debugger
    // storage.removeItem('user')
    state = undefined;
  }


  return appReducer(state, action);
};

const appReducer = combineReducers({
  page: pageReducer,
  user: userReducer
});

export default rootReducer;
