import React from 'react';
import ReactDOM from "react-dom";
import App from "./App";
import store, { persistor }  from "./store";
import "./style/index.scss";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
// import store, { persistor } from "./store";
// import { PersistGate } from "redux-persist/integration/react";


// ReactDOM.render(
// 	<Provider store={store}>
// 		<PersistGate loading={null} persistor={persistor}>
// 			<App />
// 		</PersistGate>
// 	</Provider>,
//   document.getElementById("root")
// );

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
  