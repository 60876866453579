import "./LoadingScreen.scss"

export default function LoadingScreen() {
  return (
    <div className="loader-screen">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}