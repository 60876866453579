import { 
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
  } from 'react-redux';
  import thunk from "redux-thunk";
  import { persistStore, persistReducer } from "redux-persist";
  import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
  
  
  import { configureStore } from '@reduxjs/toolkit';
  // import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
  import rootReducer from './rootReducer';
  // import { persistStore, persistReducer } from "redux-persist";
  // import apiMiddleware from "./middleware/api";
  
  export const reduxStorageKey = 'root29'
  
  const persistConfig = {
    key: reduxStorageKey,
    storage,
    // blacklist: ['dataView']
  };
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  
  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [thunk]
  
  });
  
  export const useSelector = useReduxSelector;
  
  export const useDispatch = () => useReduxDispatch();
  export const persistor = persistStore(store);
  export default store;
  