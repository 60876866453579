import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from './routes/routes';
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { translationEN, translationRO, translationHU } from "./translations/index"
import AWS from 'aws-sdk';
import ThemeContext, { theme } from './context/themeContext/themeContext';

i18next.use(initReactI18next).init({
	resources:{
		en: {translation: translationEN},
		ro: {translation: translationRO},
		hu: {translation: translationHU}
	},
	lng: localStorage.getItem("lang") ?? document?.documentElement?.getAttribute("lang"),
	fallbackLng: document?.documentElement?.getAttribute("lang") ?? "en",
	interpolation:{ escapeValue:false },
})

const history = createBrowserHistory({ forceRefresh: true });

AWS.config.region = 'eu-central-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:b4784c59-b5d3-4f1e-b40b-e8d94de2c6c6',
});


const App = () => { 
	return (
		<ThemeContext.Provider value={theme}>
			<Router history={history}>
				{renderRoutes(routes)}
			</Router>
		</ThemeContext.Provider>
	);}
export default App;