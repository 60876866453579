import { Suspense, Fragment, lazy } from "react";
import { Redirect }                 from "react-router";
import { Switch, Route }            from "react-router-dom";
import LoadingScreen                from "../components/LoadingScreen/LoadingScreen";
// import HolmioLayout           from "../layouts/HolmioLayout";
// import { holmioRoutes }       from "./holmio.routes";
// import AuthGuard from 'src/components/AuthGuard';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/",
    component:lazy(() => import("../pages/CustomerPage")),
  },
  {
    exact: true,
    path: "/links",
    component:lazy(() => import("../pages/LinksPage")),
  },
  {
    exact: true,
    path: "/test",
    component:lazy(() => import("../pages/TestPage")),
  },
  {
    exact: true,
    path: "/example",
    component:lazy(() => import("../pages/ExamplePage")),
  },
  {
    exact: true,
    path: "/farmer",
    component:lazy(() => import("../pages/FarmerPage")),
  },
  // {
  //   exact: true,
  //   path: "/customer",
  //   component:lazy(() => import("../pages/CustomerPage")),
  // },
  {
    exact: false,
    path: "*",
    component: () => <Redirect to="/" />,
  },

];

export default routes;